<template>

  <el-row :gutter='10'>
    <div class="dialogCard">
      <div class="subBox">
        <div class="title">{{meetingName }} 日报</div>
        <div class="subTitle">截止时间：{{nowTime}}</div>
      </div>

      <el-row style="width:100%;">
        <el-col :lg="4" :md="12" :sm="12" :xl="4" :xs="24" class="ul" v-for="(item, index) in realData" :key="index">
          <p class='smTitle'>{{item.name}}</p>
          <div v-if="loading">加载中...</div>
          <template v-else>
            <vab-count :decimals="item.countConfig.decimals" :duration="item.countConfig.duration" :end-val="item.countConfig.endVal" :prefix="item.countConfig.prefix" :separator="item.countConfig.separator" :start-val="item.countConfig.startVal"
              style="font-size:26px;color:#1D2129;font-weight:bold;" />
            <span style="font-size:16px;color:#4e5969">{{item.countConfig.suffix}}</span>
          </template>
        </el-col>
      </el-row>
    </div>
  </el-row>

</template>

<script>
import { defineComponent, reactive, toRefs, watch } from 'vue'
import _ from 'lodash'
import VabChart from '@/extra/VabChart'
import VabCount from '@/extra/VabCount'
import { parseTime } from '@/utils/index'

export default defineComponent({
  components: {
    VabChart,
    VabCount,
  },
  props: {
    data: {
      type: Object,
      default: {},
    },
    meetingName: {
      type: String,
      default: '',
    }
  },
  setup(props) {
    watch(
      () => props.data,
      (newVal, oldVal) => {
        state.realData[0].countConfig.endVal = newVal.signUpNum
        state.realData[1].countConfig.endVal = newVal.todayMeetingForumSignUpTimes
        state.realData[2].countConfig.endVal = newVal.totalSignUpNum
        state.realData[3].countConfig.endVal = newVal.meetingForumSignUpTimes
        state.realData[4].countConfig.endVal = newVal.signInNum
        state.realData[5].countConfig.endVal = newVal.totalSignInNum
        state.nowTime = parseTime(new Date())
        setTimeout(() => {
          state.loading = false
        }, 100)
      }
    )
    const state = reactive({
      loading: true,
      nowTime: '',
      realData: [
        {
          countConfig: {
            startVal: 0,
            endVal: 0,
            decimals: 0,
            prefix: '',
            suffix: '人',
            separator: ',',
            duration: 3000,
          },
          name: '当日注册',
          colCount: 8,
        },
        {
          countConfig: {
            startVal: 0,
            endVal: 0,
            decimals: 0,
            prefix: '',
            suffix: '人次',
            separator: ',',
            duration: 3000,
          },
          name: '当日注册活动',
          colCount: 8,
        },
        {
          countConfig: {
            startVal: 0,
            endVal: 0,
            decimals: 0,
            prefix: '',
            suffix: '人',
            separator: ',',
            duration: 3000,
          },
          name: '累计注册',
          colCount: 8,
        },
        {
          countConfig: {
            startVal: 0,
            endVal: 0,
            decimals: 0,
            prefix: '',
            suffix: '人次',
            separator: ',',
            duration: 3000,
          },
          name: '累计注册活动',
          colCount: 8,
        },
        {
          countConfig: {
            startVal: 0,
            endVal: 0,
            decimals: 0,
            prefix: '',
            suffix: '人',
            separator: ',',
            duration: 3000,
          },
          name: '当日签到',
          colCount: 8,
        },
        {
          countConfig: {
            startVal: 0,
            endVal: 0,
            decimals: 0,
            prefix: '',
            suffix: '人',
            separator: ',',
            duration: 3000,
          },
          name: '累计签到',
          colCount: 8,
        },
      ],
    })
    return {
      ...toRefs(state),
    }
  },
})
</script>


<style lang="scss" scoped>
.dialogCard {
  width: 100%;
  min-height: 174px;
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
  padding: 20px;
  box-sizing: border-box;
  .subBox {
    .title {
      font-weight: 500;
      font-size: 16px;
      color: #1d2129;
      margin-bottom: 4px;
    }
    .subTitle {
      font-size: 12px;
      color: #4e5969;
    }
  }

  .ul {
    margin-top: 15px;
    text-align: center;
    .smTitle {
      font-size: 16px;
      color: #1d2129;
    }
  }
}
</style>